import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./TermsofUse.css";
import HeaderNew from "./HeaderNew";
import FooterNew from "./FooterNew";

export default function TermofUse() {
  return (
    <>
      <HeaderNew />

      <div className="fff"></div>

      <div className="container refund_return">
        <div className="refund_return_container">
          <div className="refund_return_wrapper">
            <div className="refund_return_policy">
              <h4>Terms & Conditions</h4>
              <p>
                The term “You” stated hereunder means the user of the website.
                The term “We” stated hereunder means the website and its content
                creators. In return for your agreeing to comply with these Terms
                of Use, you may retrieve and display content from this website
                on your personal computer or mobile device for personal use /
                non-commercial use. You are specifically restricted from using
                this website for any commercial purpose. By using, accessing and
                browsing through this website, you understand that you are
                subject to our online Terms of Use as stated below. Kindly go
                through the Terms of Use. If you do not agree with any of the
                Terms of Use, you should not use or access our website or engage
                services or consume the information provided on our website. The
                following Terms of Use maybe amended at any time without any
                specific notice for broadcast.
              </p>
            </div>

            <div className="refund_return_policy">
              <ol>
                <li className="mb-3">
                  <p>
                    Though all efforts have been made to ensure the accuracy of
                    all content on this portal, the same should not be construed
                    as a statement of law or used for any legal purposes. In no
                    event will we be liable for any expense, loss, damage,
                    discomfort, lack of result and/or reaction, whether direct
                    or indirect or consequential, from the use of this website
                    or the content contained in the website.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    That any dispute arising from the use of this website shall
                    be governed and construed in accordance with the laws of
                    India and any dispute arising with respect to the present
                    website or its content in any manner shall be subject to the
                    exclusive jurisdiction of the courts of New Delhi only to
                    the exclusion of all others. It is clearly understood by you
                    that this website is solely created and managed at New
                    Delhi, India and all services, and information provided
                    herein is provided from New Delhi, India.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    It is clearly understood by you that this website is solely
                    created and managed at New Delhi, India and all services,
                    and information provided herein is provided from New Delhi,
                    India.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    We reserve the sole right and discretion to change, alter,
                    add, modify or remove any information or service provided on
                    the present website.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    PRIVACY POLICY-a) This website does not automatically
                    capture any specific personal information from you that
                    allows us to identify you individually. If we request you to
                    provide personal information, adequate security measures
                    will be taken to protect your personal information. b) We do
                    not sell or share any personally identifiable information
                    with any third party. ‍c) Certain technical information such
                    as IP addresses, Domain, etc. are gathered to run this
                    technological platform but we make no attempt to link these
                    information with the identity of individuals visiting our
                    site.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    INTELLECTUAL PROPERTY RIGHTS a) All information and content
                    contained in the present website is the sole intellectual
                    property of the content creators of the website and no one
                    is allowed to reproduce the same for public publication or
                    for personal or commercial use of others without the express
                    written permission of the content creators of this website.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    DISCLAIMERS a) The information and services being provided
                    on the present website and all its sub-pages, though
                    thoroughly backed by knowledge and experience of the content
                    creator, may vary in their results from person to person. b)
                    That the services, advises and information being provided by
                    means of the website do not guarantee any results. The
                    website or its content creator assume no responsibility of
                    any nature for errors or omissions that may appear on the
                    website. c) That the maximum liability of the website or its
                    content creator shall be restricted to the amount paid to
                    the website in case of paid services. That there shall be no
                    liability of any kind with respect to the information
                    provided for free on the website. You also understand that
                    the website or its content creators are not liable for any
                    damages or injury resulting from reliance on information
                    provided by this website. d) You also understand that the
                    website does not give any medical advise. We do not claim to
                    cure, prevent, diagnose or treat any nutrition related
                    disease or health condition. e) Certain suggested items may
                    cause allergic reaction or may not be suitable for
                    consumption for everyone and such may vary from person to
                    person. Neither this website not its content creator can be
                    held liable in case of any such issue. f) Please use caution
                    and your personal discretion when you consume as per the
                    suggestions/recommendations made on the present website. g)
                    The Disclaimer is subject to change without notice. If you
                    do not agree or understand the disclaimer, in full or in
                    part, you must not use this website and /or the services
                    associated with it. h) Under no circumstances should use of
                    this website, its content or its services be taken as
                    error-free or comprehensive. Nutrition advisories are ever
                    evolving and vary from person to person .i) The content of
                    this website is general information and does not provide
                    medical advice or diagnosis. j) Never ignore professional
                    medical advice from your medical practitioner. Consult your
                    medical practitioner before adopting any information
                    provided through this website or its services.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    TERMS OF USE WITH RESPECT TO APPOINTMENTS a) When you book
                    an appointment with us, the same maybe cancelled or
                    rescheduled at the sole discretion of the creators of this
                    website without notice and without assigning any reason
                    whatsoever b) However, in case of such cancellation, the
                    same shall not be counted and you shall be able to book
                    another appointment as per the slots available.
                  </p>
                </li>
                <li className="mb-3">
                  <p>
                    OTHER TERMS OF USE a) Links to other websites which can be
                    navigated to the present website will be given in certain
                    places. You are advised to proceed only with caution. We
                    shall not be responsible for any act, information or
                    incident taking place on such third-party website. ‍b) We
                    reserve right to terminate your right to use the present
                    website in case of breach of the Terms of Use and/or any
                    other act which may cause damage to the website or its
                    content creators.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <FooterNew />
    </>
  );
}
